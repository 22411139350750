<template>
  <div class="common-layout">
    <el-container id = "main" style="height: 100%">
      <el-header>Header</el-header>
      <el-container class="all">
        <el-aside width="300px"  class="tree-container">
            <el-tree class="custom-tree" ref="tree" :data="tree" node-key="id" @node-click="handleNodeClick" :default-expanded-keys="treeExpandelKeys" @node-contextmenu="showContextMenu" :allow-drop="allowDrag" @node-drop="handleDrag" draggable ></el-tree>
            <ul v-if="menuVisible" class="menu" :style="{top:this.viewScrollTop+menuY+'px',left:menuX+'px'}">
              <li v-for="(item, index) in filteredItems" :key="index" @click="handleClick(item)">
                {{ item.label }}
              </li>
            </ul>
        </el-aside>
        <div class="resize" >⋮</div>
        <el-main style="padding: 0px;" class="right">
          <div align="left" style="position: relative;height: 100%" v-if="markdownPath">
            <div class="edit-div1"><el-button class="update-btn" @click="handleClick">更新</el-button></div>
            <div align="left" class="edit-div2">
              <MdEditor
                  ref="editor"
                  v-model="markdown"
                  :toolbarsExclude="toolbarsExclude"
                  @onSave="saveArticle"
                  @onUploadImg="handleUploadImage"
              ></MdEditor>
            </div>
            <el-dialog v-model="dialogTableVisible" title="未提交更改" style="text-align: center;" >
              <el-table :data="gridData" style="font-size: 20px;text-align: left" fit>
                <el-table-column property="action" label="行为" min-width="5px" />
                <el-table-column property="path" label="路径" min-width="15px" />
                <el-table-column label="操作" min-width="5px">
                  <template #default="scope">
                    <el-button v-if="scope.row.action === 'add'" type="primary" size="default" @click="pushRowChange(scope.row)">更新</el-button>
                    <el-button v-else-if="scope.row.action === 'delete'" type="primary" size="default" @click="pushRowChange(scope.row)">更新</el-button>
                    <el-button v-else type="primary" size="default">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-dialog>
          </div>
        </el-main>


      </el-container>
      <el-footer>Footer</el-footer>
    </el-container>
  </div>

</template>

<script>
import axios from '@/api/axios'; // 引入封装好的api
import router from '@/router';
import { lineNumbers } from '@codemirror/view';
import { config, MdEditor } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import swal from 'sweetalert2';
import { Message } from "element-plus";

config({
  codeMirrorExtensions(_theme, extensions) {
    return [...extensions, lineNumbers()];
  }
});


// 定义一个名为 "CustomError" 的自定义错误类
class CustomError extends Error {
  constructor(message) {
    super(message); // 调用父类的构造函数 (Error)，并传递错误消息
    this.name = 'CustomError'; // 设置错误名称
  }
}

let treeID = 0;

function insertAfterIndex(arr, index, newData) {
  // 将数组分成两部分
  const firstPart = arr.slice(0, index + 1);
  const secondPart = arr.slice(index + 1);

  // 在两部分之间插入新数据
  const newArr = firstPart.concat(newData, secondPart);

  return newArr;
}

function addParent(tree) {
  if (typeof tree === 'object') {
    if (Array.isArray(tree)) {
      let res = [];
      for (let i = 0; i <tree.length ; i++) {
        res.push(addParent(tree[i]));
      }
      return res;
    } else {
        treeID = Math.max(treeID,tree.id);
        if (!tree.children || tree.children.length === 0){
            return tree
        }
        for (let i = 0; i < tree.children.length ; i++) {
             tree.children[i].parent = tree;
             tree.children[i] = addParent(tree.children[i])
        }
        return tree
    }
  } else {
       throw new CustomError('非对象类型数据结构');
  }
}

function deleteParent(tree) {
  if (typeof tree === 'object') {
    if (Array.isArray(tree)) {
      let res = [];
      for (let i = 0; i < tree.length ; i++) {
        res.push(deleteParent(tree[i]));
      }
      return res;
    } else {
      if (!tree.children || tree.children.length === 0){
        return tree
      }
      for (let i = 0; i < tree.children.length ; i++) {
        tree.children[i].parent = null;
        tree.children[i] = deleteParent(tree.children[i])
      }
      return tree
    }
  } else {
    throw new CustomError('非对象类型数据结构');
  }
}

function removePrefix(str, prefix) {
  if (str.startsWith(prefix)) {
    return str.substring(prefix.length);
  }
  return str;
}

// 左右拖动事件
function dragControllerLR() {
  let resize = document.getElementsByClassName("resize");
  let left = document.getElementsByClassName("el-aside");
  let right = document.getElementsByClassName("right");
  let box = document.getElementsByClassName("all");
  for (let i = 0; i < resize.length; i++) {
    // 鼠标按下事件
    resize[i].onmousedown = function (e) {
      let startX = e.clientX;
      resize[i].left = resize[i].offsetLeft;
      // 鼠标拖动事件
      document.onmousemove = function (e) {
        let endX = e.clientX;
        let moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
        let maxT = box[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

        if (moveLen < 300) moveLen = 300; // 左边区域的最小宽度为50px
        if (moveLen > maxT - 300) moveLen = maxT - 300; //右边区域最小宽度为150px

        resize[i].style.left = moveLen; // 设置左侧区域的宽度

        for (let j = 0; j < left.length; j++) {
          left[j].style.width = moveLen + "px";
          right[j].style.width = box[i].clientWidth - moveLen - 10 + "px";
        }
      };
      // 鼠标松开事件
      document.onmouseup = function (e) {;
        document.onmousemove = null;
        document.onmouseup = null;
      };
      return false;
    };
  }
}

export default {
  components: {
    MdEditor
  },

  unmounted() {
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("scroll", this.handleScroll);
  },

  async mounted() {
    dragControllerLR()
    const navData = await axios.post("/md/query/nav")
    this.tree = addParent(navData.data);
    if (this.markdownPath) {
      axios.post("/md/query/markdown",{
        "path": this.markdownPath
      }).then(response => {
         this.markdown = response.data.data;
      }).catch(error => {
        console.error('请求失败:', error);
        this.markdown = ''
      });
    }
    const changeRes = await axios.post("/md/query/markdown/change")

    // 将对象转换为键值对数组
    const keyValuePairs = Object.entries(changeRes.data.changeMap);
    // 使用键值对数组创建 Map
    const changeMap = new Map(keyValuePairs);

    console.log(changeMap.size);
    for (const [key, value] of changeMap) {
      console.log(`${key}: ${value}`);
      this.gridData.push({
        "path":key,
        "action":value
      })
    }

    if (this.gridData.length > 0){
      this.dialogTableVisible = true
    }
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("scroll", this.handleScroll);
    if (this.$route.query.id){
      console.log(this.$route.query.id)
      this.treeExpandelKeys = [this.$route.query.id];
    }

    if (this.clickNode){
      this.treeExpandelKeys = [this.clickNode.id];
    }

  },


  data() {
    return {
      tree:[],
      treeExpandelKeys:[],
      markdown: '',
      menuVisible: false,
      menuX: 0,
      menuY: 0,
      scrollTop:0,
      viewScrollTop:0,
      toolbarsExclude: ['github'],
      menuItems: [
        { label: '新增同级文件夹', onClick: this.addFolder },
        { label: '新增子文件夹', onClick: this.addChildFolder },
        { label: '新建文件', onClick:this.addFile},
        { label: '重命名', onClick: this.rename },
        { label: '删除', onClick: this.deleteFile },
      ],
      filteredItems:this.menuItems,
      clickNode:null,
      gridData:[],
      dialogTableVisible:false,
    };
  },
  methods: {
    addFolder() {
      treeID++;
      this.$prompt('请输入文件夹名称', '提示', {   // 弹出框用于输入文件名
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\S{1,100}$/,
        inputErrorMessage: '文件名长度在1到100之间'
      }).then(({value}) => {
        const child = {   // 新建一个子节点
          id: treeID,   // 要在script中定义一个全局变量id
          label: value,
          children:[],
        };

        const parent = this.clickNode.parent;
        let children = null;
        if (parent){
          children = parent.children;
        }else {
          children = this.tree;
        }
        const index = children.findIndex(d => d.id === this.clickNode.id);

        children = insertAfterIndex(children,index,child)
        if (parent){
          parent.children = children;
          child.parent = parent;``
        }else {
          this.tree = children;
        }
        this.$message({
          type: 'success',
          message: '文件夹新建成功！'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    addChildFolder() {
      treeID++;
      this.$prompt('请输入文件夹名称', '提示', {   // 弹出框用于输入文件名
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\S{1,100}$/,
        inputErrorMessage: '文件名长度在1到100之间'
      }).then(({value}) => {
        const child = {   // 新建一个子节点
          id: treeID,   // 要在script中定义一个全局变量id
          label: value,
          parent: this.clickNode,
          children:[],
        };
        if (!this.clickNode.children){
            this.clickNode.children = [];
        }
        this.clickNode.children.push(child);
        this.$refs.tree.store.nodesMap[this.clickNode.id].expanded=true;
        this.$message({
          type: 'success',
          message: '文件夹新建成功！'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    addFile(){
      this.hideMenu();
      treeID++;
      this.$prompt('请输入文件名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: "请输入文件名",
        inputPattern: /^\S{1,100}$/,
        inputErrorMessage: '文件名长度在1到100之间'
      }).then(async ({value}) => {
        let path = '';
        let clickNode = this.clickNode
        while (clickNode) {
          path = clickNode.label + "/" + path;
          clickNode = clickNode.parent
        }

        this.clickNode.children.push({
          label: value,
          id: treeID,
          path: path + value + ".md",
          parent:this.clickNode
        })

        let err;
        await axios.post("/md/add/markdown", {
          "path": path + value + ".md",
        }).then(response => {
          console.log(response.status)
        }).catch(error => {
          err = error
        });

        if (err){
          console.error('请求失败:', err);
          return
        }

        const tree = deleteParent(this.tree)
        await axios.post("/md/update/nav", {
          data: JSON.stringify(tree),
        }).then(response => {
          console.log(response.status)
        }).catch(error => {
          err = error
        });
        this.tree = addParent(tree);

        if (err){
          console.error('请求失败:', err);
          return
        }

        await axios.post("/md/update/nav/change").then(response => {
          console.log(response.status)
        }).catch(error => {
          err = error
        });

        if (err){
          console.error('请求失败:', err);
          return
        }

        this.$message({
          type: 'success',
          message: '文件已创建成功！'
        });
        this.treeExpandelKeys = [this.clickNode.id]
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });

    },
    deleteFile() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {

        let clickNode = this.clickNode;

        // 非叶子节点
        if (clickNode.children&& this.clickNode.children.length > 0){
            Message.error("非叶子节点不允许删除");
        }

        let err;

        // 文件
        if (clickNode.path !== ""){
            await axios.post("/md/delete/markdown",{"path":clickNode.path}).then(response => {
              console.log(response.status)
            }).catch(error => {
              err = error
            });

            if (err){
              console.error('请求失败:', err);
              return
            }
        }

        const parent = clickNode.parent;
        let children;
        if (parent) {
          children = parent.children;
        } else {
          children = this.tree;
        }

        const index = children.findIndex(d => d.id === clickNode.id);
        children.splice(index, 1);

        const tree = deleteParent(this.tree)
        await axios.post("/md/update/nav", {
          data: JSON.stringify(tree),
        }).then(response => {
          console.log(response.status)
        }).catch(error => {
          err = error
        });
        this.tree = addParent(tree);
        if (err){
          console.error('请求失败:', err);
          return
        }

        await axios.post("/md/update/nav/change").then(response => {
          console.log(response.status)
        }).catch(error => {
          err = error
        });

        if (err){
          console.error('请求失败:', err);
          return
        }

        this.$message({
          type: 'success',
          message: '删除成功!'
        });

        this.treeExpandelKeys = [this.clickNode.id]


      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    rename(){
      this.hideMenu()
      this.$prompt('请输入文件名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: this.clickNode.label,
        inputPattern: /^\S{1,100}$/,
        inputErrorMessage: '文件名长度在1到100之间'
      }).then(async ({value}) => {
        let path = '';
        let clickNode = this.clickNode.parent
        while (clickNode) {
          path = clickNode.label + "/" + path;
          clickNode = clickNode.parent
        }

        let oldValue = this.clickNode.label
        let newValue = value;
        console.log(oldValue);
        console.log(newValue);
        this.clickNode.label = value

        // 叶子节点
        if (!this.clickNode.children  && this.clickNode.path !== "") {
            this.clickNode.path = path +newValue + ".md"
        }

        let err;
        const tree = deleteParent(this.tree)
        await axios.post("/md/update/nav", {
          data: JSON.stringify(tree),
        }).then(response => {
          console.log(response.status)
        }).catch(error => {
          err = error
        });
        this.tree = addParent(tree);

        if (err){
          console.error('请求失败:', err);
          return
        }

        await axios.post("/md/update/nav/change").then(response => {
          console.log(response.status)
        }).catch(error => {
          err = error
        });

        if (err){
          console.error('请求失败:', err);
          return
        }

        // 叶子节点
        if (!this.clickNode.children  && this.clickNode.path !== "") {
            await axios.post("/md/rename/markdown", {
              "oldPath":path +oldValue + ".md",
              "newPath":path +newValue + ".md"
            }).then(response => {
               console.log(response.status)
            }).catch(error => {
               err = error
            });
        }

        if (err){
          console.error('请求失败:', err);
          return
        }

        this.$message({
          type: 'success',
          message: '文件夹已重命名！'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
      this.treeExpandelKeys = [this.clickNode.id]
    },

    // tree拖拽成功完成时触发的事件
    handleDrag(draggingNode, dropNode, type, event) {
        let err;
        if ((type==="before"||type==="after")){
          this.$confirm('此操作将不可逆, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {


            const tree = deleteParent(this.tree)
            await axios.post("/md/update/nav", {
              data: JSON.stringify(tree),
            }).then(response => {
              console.log(response.status)
            }).catch(error => {
              err = error
            });
            this.tree = addParent(tree);

            if (err){
              console.error('请求失败:', err);
              return
            }

            await axios.post("/md/update/nav/change").then(response => {
              console.log(response.status)
            }).catch(error => {
              err = error
            });

            if (err){
              console.error('请求失败:', err);
              return
            }

            this.$message({
              type: 'success',
              message: '文件夹移动成功！'
            });

            this.treeExpandelKeys = [draggingNode.data.id];

          }).catch(() => {
            this.$message({
              type: 'info',
              message: '操作已取消'
            });
          });

        }else if (type === "inner"){
          this.$confirm('此操作将不可逆, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            let oldPath = draggingNode.data.path
            let newPath = ''
            let parent = dropNode

            while (parent&&parent.data.label){
              newPath = parent.data.label + "/" + newPath
              parent = parent.parent
            }
            newPath = newPath + draggingNode.data.label + ".md"

            draggingNode.data.path = newPath

            await axios.post("/md/replace/markdown",{"oldPath":oldPath,"newPath":newPath}).then(response => {
              console.log(response.status)
            }).catch(error => {
              err = error
            });

            if (err){
              console.error('请求失败:', err);
              return
            }

            const tree = deleteParent(this.tree)
            await axios.post("/md/update/nav", {
              data: JSON.stringify(tree),
            }).then(response => {
              console.log(response.status)
            }).catch(error => {
              err = error
            });
            this.tree = addParent(tree);

            if (err){
              console.error('请求失败:', err);
              return
            }

            await axios.post("/md/update/nav/change").then(response => {
              console.log(response.status)
            }).catch(error => {
              err = error
            });

            if (err){
              console.error('请求失败:', err);
              return
            }

            this.$message({
              type: 'success',
              message: '文件移动成功！'
            });

          }).catch(() => {
            this.$message({
              type: 'info',
              message: '操作已取消'
            });
          });
        }
    },
    // 拖拽时判定目标节点能否被放置
    // 'prev'、'inner' 和 'next'，分前、插入、后
    allowDrag(draggingNode, dropNode, type) {
      if (type == "inner"){
          return draggingNode.isLeaf && !dropNode.isLeaf
      }
      if (draggingNode.data.level === dropNode.data.level) {
        if (draggingNode.data.parentId === dropNode.data.parentId) {
          return type === "prev" || type === "next";
        } else {
          return false;
        }
      } else {
        // 不同级进行处理
        return false;
      }
    },

    handleNodeClick(nodeData) {
      this.hideMenu();
      this.clickNode = nodeData;
      if (nodeData.path) {
        axios.post("/md/query/markdown",{
          "path": "/"+nodeData.path
        }).then(response => {
          this.markdown = response.data.data;
        }).catch(error => {
          console.error('请求失败:', error);
          this.markdown = ''
        });
      }

      if (nodeData.path) {
        router.push('/edit/' + nodeData.path);
      }
    },

    showContextMenu(event, nodeData) {
      if (nodeData !== this.clickNode){
         this.hideMenu();
         this.$refs.tree.setCurrentKey(nodeData.id);
         this.handleNodeClick(nodeData)
      }

      this.filteredItems = this.menuItems.filter(item => {
        // 叶子节点
        if (!this.clickNode.children  && this.clickNode.path !== "") {
          return (item.label === "重命名" || item.label === "删除");
        }

        if (this.clickNode.children && this.clickNode.children.length === 0){
           return true;
        }

        if (!this.clickNode.children[0].children || this.clickNode.children[0].children.length ===0 ){
          return ( item.label === "新增同级文件夹" || item.label === "新增子文件夹" || item.label === "新建文件" || item.label === "重命名" || item.label === "删除");
        }

        return ( item.label === "新增同级文件夹" || item.label === "新增子文件夹" || item.label === "重命名" || item.label === "删除");
      });
      event.preventDefault();
      this.menuVisible = true;
      this.viewScrollTop = this.scrollTop;
      this.menuX = event.clientX;
      this.menuY = event.clientY;
    },

    handleScroll() {
      this.scrollTop = document.documentElement.scrollTop;
    },

    hideMenu() {
      this.menuVisible = false;
    },
    handleClick(item) {
      item.onClick();
      this.hideMenu();
    },

    handleClickOutside() {
      this.hideMenu();
    },
    // eslint-disable-next-line no-unused-vars
    saveArticle(v,h) {
      const path = removePrefix(decodeURIComponent(this.$route.path),"/edit/")
      axios.post("/md/update/markdown",{
        "path": path,
        "data":v
      }).then(response => {
        console.log(response.status)
      }).catch(error => {
        console.error('请求失败:', error);
      });
    },

    // eslint-disable-next-line no-unused-vars
    async handleUploadImage(files, callback) {
      console.log(this.$route.params.path)
      let path = this.$route.params.path
      let filename = path.substring(path.lastIndexOf('/')+1);
      let dir = filename.substring(0, filename.lastIndexOf("."))
      console.log(filename)
      let insertImages = []
      for (const file of files) {
        const formData = new FormData
        formData.append("file",file)
        formData.append("dir",dir)
        let timestamp = new Date().getTime();
        formData.append("filename",timestamp+"_"+file.name)

        const response = await axios.post('/img/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((loaded / total) * 100);
            swal.fire({
              title: '上传进度',
              text: `${progress}%`,
              icon: 'info',
              timer: 1000,
              showConfirmButton: false
            });
          }
        });
        if (response.status === 200) {
          await swal.fire('成功', '文件上传成功！', 'success');
          let url = "https://mc.wsh-study.com/mkdocs/"+dir+"/"+timestamp+"_"+file.name;
          insertImages.push({
            url:url,
            alt:file.name,
          })
        } else {
          await swal.fire('错误', '文件上传失败！', 'error');
        }
      }

      callback(insertImages)
    },
    pushChange(path){
      axios.post("/md/change",{
        "path": path,
        "submodule":"update"
      }).then(response => {
        console.log(response.status)
      }).catch(error => {
        console.error('请求失败:', error);
      });
    },
    pushRowChange(row){
      this.pushChange(row.path)
    },
  },
  computed:{
    markdownPath:{
      get(){
        return this.$route.params.path== undefined ? '':`/${this.$route.params.path}`;
      },
      set(){}
    },
  }
};

</script>

<style>
.common-layout{
  height: 100%;
  bottom: 0;
}

.main{
  height: 100%;
  bottom: 0;
  text-align: left;
}

.el-header {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-footer{
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
  bottom: 0;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
}


.tree-container {
  height: 100%;
  border-right: 1px solid #dcdfe6; /* 根据需要设置边框颜色 */
  overflow-y: auto;
  overflow-x: auto;
  background-color: #ffffff;
}

.custom-tree {
  height: 100%;
  min-width: 100%;
  display: inline-block !important;
}
/* 修改树节点的字体大小 */
.custom-tree .el-tree-node__content {
  height: 50px; /* 设置为你想要的高度 */
  font-size: 20px;
  background-color: #ffffff;
  line-height: 40px;
}

/* 修改树节点选中时的背景颜色 */
.custom-tree .el-tree-node.is-current > .el-tree-node__content {
  background-color: #409eff;
}

/* 修改树节点悬停时的背景颜色 */
.custom-tree .el-tree-node__content:hover {
  background-color: #e5e5e5;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 30px;
}

.el-aside::-webkit-scrollbar {
  display: none;
}

.md-editor{
  height: 100%;
}

.cm-editor{
  font-size: 18px;
}
.menu {
  position: absolute;
  border: 1px solid #ccc;
  background-color: white;
  padding: 5px;
  z-index: 1000;
}
.menu li {
  cursor: pointer;
  width: 120px;
  height: 50px;
  list-style-type:none;
  line-height: 50px;
}
.menu li:hover {
  background-color: #eee;
}

.edit-div1 {
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
  width: 100%;
  height: 60px;
  background-color: #ffffff;
}

.edit-div2{
  position: absolute;
  top: 50px;
  width: 100%;
  height: calc(100% - 50px);
}

.update-btn{
  position: absolute;
  right: 20px;
  top:20px;
}

.el-dialog__title {
  color: #db5955 !important; /* 修改标题颜色 */
  font-size: 25px !important; /* 修改标题字体大小 */
}
</style>

